import { Bar } from "react-chartjs-2";
import { useContext } from "react";
import { Card } from "react-bootstrap";
import {
  QUESTION_5,
  RATING_PRODUCT_FEATURE_BAR_CHART_DATA,
  RATING_PRODUCT_FEATURE_LABELS,
  RATING_PRODUCT_FEATURE_LIST,
} from "../../constants/constants";
import { ColorPaletteContext } from "../../App";

const RatingProductFeatureBarChartQuestion5 = (props) => {
  const colorPaletteContext = useContext(ColorPaletteContext);
  const { colorPaletteState } = colorPaletteContext;

  function getAveragePercentageForEachOption(
    index,
    ratingProductFeatureIndex,
    resultArray = []
  ) {
    if (props.countryFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q0 === props.countryFilterValue
      );
    }

    var test = resultArray.filter(
      (obj) =>
        obj[`Q5_${ratingProductFeatureIndex + 1}`] === "1" && obj.Q5 === index
    );

    var temp = (
      (test.length / resultArray.filter((obj) => obj.Q5 === index).length) *
      100
    ).toFixed(0);

    return temp;
  }

  const data = {
    labels: RATING_PRODUCT_FEATURE_LABELS.map((item) => item.value),
    // datasets: [
    //   {
    //     label: "Excellent",
    //     data: [20, 10, 17],
    //     backgroundColor: colorPaletteState[0],
    //   },
    //   {
    //     label: "Good",
    //     data: [10, 25, 22],
    //     backgroundColor: colorPaletteState[1],
    //   },
    //   {
    //     label: "Fair",
    //     data: [24, 19, 15],
    //     backgroundColor: colorPaletteState[2],
    //   },
    //   {
    //     label: "Poor",
    //     data: [11, 20, 10],
    //     backgroundColor: colorPaletteState[3],
    //   },
    // ],
    datasets: RATING_PRODUCT_FEATURE_LIST.map(
      (item, ratingProductFeatureIndex) => ({
        label: item.value,
        data: RATING_PRODUCT_FEATURE_LIST.map((obj, index) =>
          getAveragePercentageForEachOption(
            obj.realValue,
            ratingProductFeatureIndex,
            RATING_PRODUCT_FEATURE_BAR_CHART_DATA
          )
        ),
        backgroundColor: colorPaletteState[ratingProductFeatureIndex],
      })
    ),
  };

  function getChartOption() {
    return {
      layout: {
        padding: {
          left: 0, // Add left padding
          right: 0, // Add right padding
          top: 30, // Add top padding
          bottom: 5, // Add bottom padding
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem, data) => {
              return tooltipItem.formattedValue + "%";
            },
          },
        },
        legend: {
          display: true,
          position: "bottom",
          title: { display: true, padding: 10 },
          onClick: null, // Disable legend item click event
        },
        datalabels: {
          formatter: (value) => {
            return value + " %";
          },
          anchor: "end",
          align: "top",
          color: "grey",
          font: {
            weight: "bold",
            size: 13,
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              //weight: "bold",
              //size: 10,
            },
          },
        },
        y: {
          grid: {
            display: false,
          },
          //display: false,
        },
      },
    };
  }

  return (
    <Card>
      <Card.Header>{QUESTION_5}</Card.Header>
      <Card.Body>
        <div style={{ width: "100%", height: "60vh" }}>
          <Bar data={data} options={getChartOption()} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default RatingProductFeatureBarChartQuestion5;
