import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  COLORS_RYGU,
  MANUFACTURER_PERFERENCE_Q8_LIST,
  MANUFACTURER_PREFERENCE_BAR_CHART_DATA,
  QUESTION_9,
  SWITCHING_MANUFACTURER_BAR_CHART_DATA,
  SWITCHING_MANUFACTURER_Q9_LIST,
} from "../../constants/constants.js";
import { Card } from "react-bootstrap";
import { useContext } from "react";
import { ColorPaletteContext } from "../../App.js";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

function ManufacturerPreferenceBarChartQuestion9(props) {
  //const { dashboardData } = useAPI();

  const colorPaletteContext = useContext(ColorPaletteContext);
  const { colorPaletteState } = colorPaletteContext;

  function getOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0, // Add left padding
          right: 0, // Add right padding
          top: 30, // Add top padding
          bottom: 5, // Add bottom padding
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: (context) => {
              return context[0].label.replaceAll(",", " ");
            },
            label: (tooltipItem) => {
              return tooltipItem.formattedValue + "%";
            },
          },
        },
        legend: {
          display: false,
        },
        datalabels: {
          formatter: (value) => {
            return value + " %";
          },
          anchor: "end",
          align: "top",
          color: "grey",
          font: {
            weight: "bold",
            size: 13,
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
          //display: false,
        },
      },
    };
  }

  function getAveragePercentageForEachOption(resultArray = []) {
    if (props.countryFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q0 === props.countryFilterValue
      );
    }

    var test = SWITCHING_MANUFACTURER_Q9_LIST.map(
      (item, index) =>
        resultArray.filter((obj) => obj.Q9 === item.realValue).length
    );

    return test.map((item, index) => {
      return ((Number(item) / resultArray.length) * 100).toFixed(0);
    });
  }

  function getChartData() {
    return {
      labels: SWITCHING_MANUFACTURER_Q9_LIST.map((item, index) => item.value),
      datasets: [
        {
          label: "Switching Manufacturer",
          //data: getAveragePercentageForEachOption(dashboardData),
          //data: [5, 19, 31, 19, 13, 11, 1],
          data: getAveragePercentageForEachOption(
            SWITCHING_MANUFACTURER_BAR_CHART_DATA
          ),
          borderColor: colorPaletteState[0],
          backgroundColor: colorPaletteState[0],
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }

  return (
    <Card>
      <Card.Header>{QUESTION_9}</Card.Header>
      <Card.Body>
        <div style={{ width: "100%", height: "40vh" }}>
          <Bar options={getOptions()} data={getChartData()} />
        </div>
      </Card.Body>
    </Card>
  );
}
export default ManufacturerPreferenceBarChartQuestion9;
