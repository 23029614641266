import { useReducer, useContext } from "react";
import { Button, Col, Form, FormGroup, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { FilterContext } from "../../App";

function FilterModal(props) {
  const { show, onClose } = props;

  const filterContext = useContext(FilterContext);

  const { updateFilterState } = filterContext;

  const initialFilterState = {
    countryFilterLabel: "",
    countryFilterValue: "",
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "COUNTRY":
        return {
          ...state,
          countryFilterLabel: action.payload.label,
          countryFilterValue: action.payload.value,
        };
      case "RESET":
        return {
          initialFilterState,
        };
      default:
        return state;
    }
  };

  const [filterState, dispatch] = useReducer(reducer, initialFilterState);

  const countryOptions = [
    { value: "1", label: "U.S" },
    { value: "2", label: "UK" },
    { value: "3", label: "Spain" },
    { value: "4", label: "Japan" },
  ];

  const handleChange = (type, payload) => {
    dispatch({ type: type, payload: payload });
  };

  const onApplyFilters = () => {
    updateFilterState(filterState);
    onClose();
  };

  const handleClearFilters = () => {
    updateFilterState(initialFilterState);
    dispatch({ type: "RESET" });
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Records</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="applied-filter"></div>
        <Form>
          <FormGroup className="mb-3" as={Row}>
            <Form.Label column xs={2} md={2}>
              Country:
            </Form.Label>
            <Col xs={7} md={7} className="small">
              <Select
                name="countries"
                options={countryOptions}
                defaultValue={{
                  value: filterState.countryFilterValue,
                  label: filterState.countryFilterLabel,
                }}
                isClearable={true}
                onChange={(event) =>
                  handleChange("COUNTRY", {
                    value: event?.value,
                    label: event?.label,
                  })
                }
              />
            </Col>
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={onApplyFilters}>
          Apply Filters
        </Button>
        <Button variant="primary" onClick={handleClearFilters}>
          Clear Filters
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FilterModal;
