import {
  COLORS_RYGU,
  SATISFACTION_LEVEL_LIST,
  QUESTION_1,
  SATISFACTION_LEVEL_DATA,
} from "../../constants/constants.js";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Card } from "react-bootstrap";
import { PieChartOptions } from "../../constants/BarChartOptions.js";
import { ColorPaletteContext } from "../../App.js";
import { useContext } from "react";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

export default function PieChartQuestion1(props) {
  const colorPaletteContext = useContext(ColorPaletteContext);

  const { colorPaletteState } = colorPaletteContext;

  function getCountryCount(idVal, arr = []) {
    let finalArray = arr;

    if (props.countryFilterValue) {
      finalArray = finalArray.filter(
        (item) => item.Q0 === props.countryFilterValue
      );
    }

    return (
      (finalArray.filter((obj) => obj.Q1 === idVal).length /
        finalArray.length) *
      100
    ).toFixed(0);
  }

  function getChartData() {
    return {
      labels: SATISFACTION_LEVEL_LIST.map((item, index) => item.value),
      datasets: [
        {
          label: "Satisfaction Level",
          data: SATISFACTION_LEVEL_LIST.map((item, index) =>
            getCountryCount(item.key, SATISFACTION_LEVEL_DATA)
          ),
          borderColor: colorPaletteState,
          backgroundColor: colorPaletteState,
          borderWidth: 1,
          hoverOffset: 4,
        },
      ],
    };
  }

  return (
    <Card>
      <Card.Header>{QUESTION_1}</Card.Header>
      <Card.Body>
        <div style={{ width: "100%", height: "60vh" }}>
          <Pie options={PieChartOptions} data={getChartData()} />
        </div>
      </Card.Body>
    </Card>
  );
}
