import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Card } from "react-bootstrap";
import {
  QUESTION_7,
  SOFTWARE_ADOPTION_BAR_CHART_DATA,
  SOFTWARE_ADOPTION_LABELS,
  SOFTWARE_ADOPTION_LIST,
} from "../../constants/constants";
import { useContext } from "react";
import { ColorPaletteContext } from "../../App";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

const StackedBarChartQuestion7 = (props) => {
  const colorPaletteContext = useContext(ColorPaletteContext);
  const { colorPaletteState } = colorPaletteContext;

  function getAveragePercentageForEachOption(
    index,
    softwareAdoptionIndex,
    resultArray = []
  ) {
    if (props.countryFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q0 === props.countryFilterValue
      );
    }

    var test = resultArray.filter(
      (obj) =>
        obj[`Q7_${softwareAdoptionIndex + 1}`] === "1" && obj.Q7 === index
    );

    var temp = (
      (test.length / resultArray.filter((obj) => obj.Q7 === index).length) *
      100
    ).toFixed(0);

    return temp;
  }

  const data = {
    labels: SOFTWARE_ADOPTION_LABELS.map((item) => item.value),
    datasets: SOFTWARE_ADOPTION_LIST.map((item, adoptionIndex) => ({
      label: item.value,
      data: SOFTWARE_ADOPTION_LABELS.map((obj, index) =>
        getAveragePercentageForEachOption(
          obj.key,
          adoptionIndex,
          SOFTWARE_ADOPTION_BAR_CHART_DATA
        )
      ),
      backgroundColor: colorPaletteState[adoptionIndex],
    })),
    // datasets: [
    //   {
    //     label: "Not Started",
    //     data: [20, 10, 30, 70],
    //     backgroundColor: colorPaletteState[0],
    //   },
    //   {
    //     label: "Adoption In-Progress",
    //     data: [150, 20, 20, 20],
    //     backgroundColor: colorPaletteState[1],
    //   },
    //   {
    //     label: "Full Adoption",
    //     data: [30, 70, 50, 10],
    //     backgroundColor: colorPaletteState[2],
    //   },
    // ],
  };

  function getOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0, // Add left padding
          right: 0, // Add right padding
          top: 30, // Add top padding
          bottom: 5, // Add bottom padding
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          title: { display: true, padding: 10 },
          onClick: null, // Disable legend item click event
          labels: {
            font: {
              //size: 12,
              //weight: "bold",
            },
          },
        },
        tooltip: {
          callbacks: {
            title: (context) => {
              return context[0].label.replaceAll(",", " ");
            },
            label: (tooltipItem) => {
              return tooltipItem.formattedValue + "%";
            },
          },
        },
        datalabels: {
          formatter: (value) => {
            return value + " %";
          },
          anchor: "center",
          align: "center",
          color: "white",
          font: {
            weight: "bold",
            size: 13,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
          },
        },
      },
    };
  }

  return (
    <Card>
      <Card.Header>{QUESTION_7}</Card.Header>
      <Card.Body>
        <div style={{ width: "100%", height: "60vh" }}>
          <Bar data={data} options={getOptions()} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default StackedBarChartQuestion7;
