const COLOR_RED = "#EE4B2B";
const COLOR_BLUE = "#36A2EB";
const COLOR_YLW = "#F2AF00";
const COLOR_GRN = "#7AB800";
const COLOR_COMP = "#4472C4";
const COLOR_CANC = "#672C7E";
const COLOR_UNK = "#A9A9A9";

export const TABLE_ID = "/848/";
export const PAGE_SIZE = 1000;

export const TITLE_FONT_SIZE = 12;
export const TITLE_FONT_FACE = "Avenir Next LT Pro (Body)";
export const LEGEND_FONT_FACE = "Avenir Next LT Pro (Body)";
export const DATA_LABEL_FONT_FACE = "Avenir Next LT Pro (Body)";

export const DATA_LABEL_FONT_SIZE = 8;

export const COLORS_RYGU = [
  COLOR_RED,
  COLOR_BLUE,
  COLOR_YLW,
  COLOR_GRN,
  COLOR_COMP,
  COLOR_CANC,
  COLOR_UNK,
];

// Question List
export const QUESTION_1 =
  "Question 1: Please rate your level of satisfaction with a new software product?";

export const QUESTION_2 =
  "Question 2: How are product sales distributed among different geographical regions?";

export const QUESTION_3 =
  "Question 3: Percentage of food budget the average U.S. family spent on restaurant meals in different years";

export const QUESTION_4 =
  "Question 4: Number of Sales by Payment Method per Month (2022)";

export const QUESTION_5 =
  "Question 5: Rating a product's features(User-friendliness, Price, Performance)";

export const QUESTION_6 =
  "Question 6: What impact do you anticipate if your primary facility started using new software solution?";

export const QUESTION_7 =
  "Question 7: Distribution of each Software adoption based on their adoption status";

export const QUESTION_8 =
  "Question 8: How likely is it that you would buy new software from a manufacturer offering these types of solutions, compared to one that does not?";

export const QUESTION_9 =
  "Question 9: How likely is it that you would switch your existing solution to new software solution, if they offered solutions like the ones discussed?";

export const SATISFACTION_LEVEL_LIST = [
  { key: "1", value: "Very Satisfied" },
  { key: "2", value: "Satisfied" },
  { key: "3", value: "Neutral" },
  { key: "4", value: "Dissatisfied" },
];

export const SOFTWARE_ADOPTION_LIST = [
  { key: "Q7", realValue: "1", value: "Not Started" },
  { key: "Q7", realValue: "2", value: "Adoption In-Progress" },
  { key: "Q7", realValue: "3", value: "Full Adoption" },
];

export const RATING_PRODUCT_FEATURE_LIST = [
  { key: "Q5", realValue: "1", value: "Excellant" },
  { key: "Q5", realValue: "2", value: "Good" },
  { key: "Q5", realValue: "3", value: "Fair" },
  { key: "Q5", realValue: "4", value: "Poor" },
];

export const LINE_CHART_LIST = [
  { key: "Q3", realValue: "1", value: "Fast food Meals" },
  { key: "Q3", realValue: "2", value: "Sit-down Restaurant Meals" },
  { key: "Q3", realValue: "3", value: "Drive-thru Meals" },
];

export const RATING_PRODUCT_FEATURE_LABELS = [
  { key: "1", value: "User-friendliness" },
  { key: "2", value: "Price" },
  { key: "3", value: "Performance" },
];

export const AREA_CHART_LABELS = [
  { key: "1", value: "January" },
  { key: "2", value: "February" },
  { key: "3", value: "March" },
  { key: "4", value: "April" },
  { key: "5", value: "May" },
  { key: "6", value: "June" },
  { key: "7", value: "July" },
  { key: "8", value: "August" },
  { key: "9", value: "September" },
  { key: "10", value: "October" },
  { key: "11", value: "November" },
  { key: "12", value: "December" },
];

export const LINE_CHART_LABELS = [
  { key: "1", value: "2000" },
  { key: "2", value: "2005" },
  { key: "3", value: "2010" },
  { key: "4", value: "2015" },
  { key: "5", value: "2020" },
];

export const PERCEIVED_BENEFITS_Q6_1_LIST = [
  {
    key: "Q24r1",
    key1: "Q24_numr1",
    value: [
      "Question1:",
      "Time",
      "saving",
      "for",
      "operational",
      "staff",
      "(Reprocessing,",
      "Biomed,",
      "Hospital admin,",
      " Procurement)",
    ],
  },
  {
    key: "Q24r2",
    key1: "Q24_numr2",
    value: [
      "Question 2:",
      "Time",
      "savings",
      "for",
      "clinical",
      "staff",
      "(Physician,",
      "endoscopy nurse)",
    ],
  },
  {
    key: "Q24r3",
    key1: "Q24_numr3",
    value: [
      "Question 3:",
      "Reduced",
      "administrative",
      "work",
      "for",
      "clinical",
      "staff",
    ],
  },
  {
    key: "Q24r4",
    key1: "Q24_numr4",
    value: [
      "Question 4:",
      "Streamlined",
      "procedures",
      "(less",
      "time",
      "needed",
      "per",
      "procedure)",
    ],
  },
  {
    key: "Q24r5",
    key1: "Q24_numr5",
    value: [
      "Question 5:",
      "Reduced",
      "schedule",
      "interruptions",
      "/delays",
      "in",
      "procedures",
    ],
  },
  {
    key: "Q24r6",
    key1: "Q24_numr6",
    value: ["Question 6:", "Reduced", "repair", "and", "maintenance", "cases"],
  },
  {
    key: "Q24r7",
    key1: "Q24_numr7",
    value: [
      "Question 7:",
      "Additional",
      "capacity",
      "for",
      "additional",
      "endoscopy",
      "procedures",
    ],
  },
  {
    key: "Q24r8",
    key1: "Q24_numr8",
    value: [
      "Question 8:",
      "Increased",
      "equipment",
      "transparency",
      "(status,",
      "condition,",
      "location,",
      "utilization,",
    ],
  },
  {
    key: "Q24r9",
    key1: "Q24_numr9",
    value: ["Question 9:", "Frictionless", "reprocessing", "cycle"],
  },
  {
    key: "Q24r10",
    key1: "Q24_numr10",
    value: ["Question 10:", "Increased satff satisfaction"],
  },
  {
    key: "Q24r11",
    key1: "Q24_numr11",
    value: ["Question 11:", "Improved", "patient", "care"],
  },
  {
    key: "Q24r12",
    key1: "Q24_numr12",
    value: ["Question 12:", "Other (specify)"],
  },
];

export const MANUFACTURER_PERFERENCE_Q8_LIST = [
  {
    key: "Q8",
    realValue: "7",
    value: ["Very", "likely"],
  },
  {
    key: "Q8",
    realValue: "6",
    value: ["Likely"],
  },
  {
    key: "Q8",
    realValue: "5",
    value: ["Somewhat", " likely"],
  },
  {
    key: "Q8",
    realValue: "4",
    value: ["Neutral"],
  },
  {
    key: "Q8",
    realValue: "3",
    value: ["Somewhat", "unlikely"],
  },
  {
    key: "Q8",
    realValue: "2",
    value: ["Unlikely"],
  },
  {
    key: "Q8",
    realValue: "1",
    value: ["Very", "unlikely"],
  },
];

export const SWITCHING_MANUFACTURER_Q9_LIST = [
  {
    key: "Q9",
    realValue: "7",
    value: ["Very", "likely"],
  },
  {
    key: "Q9",
    realValue: "6",
    value: ["Likely"],
  },
  {
    key: "Q9",
    realValue: "5",
    value: ["Somewhat", " likely"],
  },
  {
    key: "Q9",
    realValue: "4",
    value: ["Neutral"],
  },
  {
    key: "Q9",
    realValue: "3",
    value: ["Somewhat", "unlikely"],
  },
  {
    key: "Q9",
    realValue: "2",
    value: ["Unlikely"],
  },
  {
    key: "Q9",
    realValue: "1",
    value: ["Very", "unlikely"],
  },
];

export const SATISFACTION_LEVEL_DATA = [
  { Q0: "1", Q1: "1" },
  { Q0: "1", Q1: "1" },
  { Q0: "1", Q1: "1" },
  { Q0: "1", Q1: "1" },
  { Q0: "1", Q1: "1" },
  { Q0: "1", Q1: "1" },
  { Q0: "1", Q1: "2" },
  { Q0: "1", Q1: "2" },
  { Q0: "1", Q1: "3" },
  { Q0: "1", Q1: "3" },
  { Q0: "1", Q1: "3" },
  { Q0: "1", Q1: "4" },
  { Q0: "2", Q1: "2" },
  { Q0: "2", Q1: "2" },
  { Q0: "2", Q1: "1" },
  { Q0: "2", Q1: "4" },
  { Q0: "3", Q1: "1" },
  { Q0: "3", Q1: "2" },
  { Q0: "3", Q1: "3" },
  { Q0: "4", Q1: "1" },
  { Q0: "4", Q1: "4" },
];

export const SALES_BY_REGION_LABELS = [
  { key: "1", value: "North America" },
  { key: "2", value: "Europe" },
  { key: "3", value: "Asia" },
  { key: "4", value: "South America" },
];

export const SOFTWARE_ADOPTION_LABELS = [
  { key: "1", value: "Software A" },
  { key: "2", value: "Software B" },
  { key: "3", value: "Software C" },
  { key: "4", value: "Software D" },
];

export const SALES_BY_REGION_DATA = [
  { Q0: "1", Q2: "350000" },
  { Q0: "1", Q2: "220000" },
  { Q0: "1", Q2: "180000" },
  { Q0: "2", Q2: "120000" },
];

export const RATING_PRODUCT_FEATURE_BAR_CHART_DATA = [
  { Q0: "1", Q5: "1", Q5_1: "1", Q5_2: "0", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "1", Q5_1: "1", Q5_2: "0", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "1", Q5_1: "1", Q5_2: "0", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "1", Q5_1: "0", Q5_2: "0", Q5_3: "1", Q5_4: "0" },
  { Q0: "1", Q5: "1", Q5_1: "0", Q5_2: "0", Q5_3: "1", Q5_4: "0" },
  { Q0: "1", Q5: "1", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "1", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "1", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "1", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "1", Q5_1: "0", Q5_2: "0", Q5_3: "0", Q5_4: "1" },

  { Q0: "1", Q5: "1", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "1", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "1", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "1", Q5_1: "0", Q5_2: "0", Q5_3: "0", Q5_4: "1" },

  { Q0: "1", Q5: "2", Q5_1: "0", Q5_2: "0", Q5_3: "1", Q5_4: "0" },
  { Q0: "1", Q5: "2", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "2", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "2", Q5_1: "0", Q5_2: "0", Q5_3: "1", Q5_4: "0" },

  { Q0: "1", Q5: "2", Q5_1: "1", Q5_2: "0", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "2", Q5_1: "0", Q5_2: "0", Q5_3: "1", Q5_4: "0" },
  { Q0: "1", Q5: "2", Q5_1: "0", Q5_2: "0", Q5_3: "0", Q5_4: "1" },
  { Q0: "1", Q5: "2", Q5_1: "0", Q5_2: "0", Q5_3: "1", Q5_4: "0" },

  { Q0: "1", Q5: "3", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "3", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "3", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "1", Q5: "3", Q5_1: "0", Q5_2: "0", Q5_3: "0", Q5_4: "1" },

  { Q0: "2", Q5: "1", Q5_1: "1", Q5_2: "0", Q5_3: "0", Q5_4: "0" },
  { Q0: "2", Q5: "2", Q5_1: "1", Q5_2: "0", Q5_3: "0", Q5_4: "0" },
  { Q0: "2", Q5: "2", Q5_1: "1", Q5_2: "0", Q5_3: "0", Q5_4: "0" },
  { Q0: "2", Q5: "2", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "2", Q5: "2", Q5_1: "0", Q5_2: "0", Q5_3: "1", Q5_4: "0" },
  { Q0: "2", Q5: "2", Q5_1: "0", Q5_2: "0", Q5_3: "1", Q5_4: "0" },
  { Q0: "2", Q5: "2", Q5_1: "0", Q5_2: "0", Q5_3: "1", Q5_4: "0" },
  { Q0: "2", Q5: "2", Q5_1: "0", Q5_2: "0", Q5_3: "0", Q5_4: "1" },

  { Q0: "2", Q5: "2", Q5_1: "1", Q5_2: "0", Q5_3: "0", Q5_4: "0" },
  { Q0: "2", Q5: "2", Q5_1: "1", Q5_2: "0", Q5_3: "0", Q5_4: "0" },
  { Q0: "2", Q5: "2", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "2", Q5: "2", Q5_1: "0", Q5_2: "0", Q5_3: "1", Q5_4: "0" },
  { Q0: "2", Q5: "2", Q5_1: "0", Q5_2: "0", Q5_3: "1", Q5_4: "0" },
  { Q0: "2", Q5: "2", Q5_1: "0", Q5_2: "0", Q5_3: "1", Q5_4: "0" },
  { Q0: "2", Q5: "2", Q5_1: "0", Q5_2: "0", Q5_3: "0", Q5_4: "1" },

  { Q0: "2", Q5: "3", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "2", Q5: "1", Q5_1: "1", Q5_2: "0", Q5_3: "0", Q5_4: "0" },
  { Q0: "2", Q5: "1", Q5_1: "1", Q5_2: "0", Q5_3: "0", Q5_4: "0" },
  { Q0: "2", Q5: "1", Q5_1: "1", Q5_2: "0", Q5_3: "0", Q5_4: "0" },
  { Q0: "3", Q5: "3", Q5_1: "1", Q5_2: "0", Q5_3: "0", Q5_4: "0" },
  { Q0: "3", Q5: "3", Q5_1: "1", Q5_2: "0", Q5_3: "0", Q5_4: "0" },
  { Q0: "3", Q5: "3", Q5_1: "0", Q5_2: "0", Q5_3: "1", Q5_4: "0" },
  { Q0: "3", Q5: "3", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "4", Q5: "3", Q5_1: "0", Q5_2: "1", Q5_3: "0", Q5_4: "0" },
  { Q0: "4", Q5: "3", Q5_1: "0", Q5_2: "0", Q5_3: "0", Q5_4: "1" },
];

export const SOFTWARE_ADOPTION_BAR_CHART_DATA = [
  { Q0: "1", Q7: "1", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "1", Q7: "1", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "1", Q7: "1", Q7_1: "0", Q7_2: "1", Q7_3: "0" },
  { Q0: "1", Q7: "1", Q7_1: "0", Q7_2: "0", Q7_3: "1" },
  { Q0: "1", Q7: "1", Q7_1: "0", Q7_2: "0", Q7_3: "1" },
  { Q0: "1", Q7: "1", Q7_1: "0", Q7_2: "0", Q7_3: "1" },
  { Q0: "1", Q7: "1", Q7_1: "0", Q7_2: "0", Q7_3: "1" },
  { Q0: "1", Q7: "2", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "1", Q7: "2", Q7_1: "0", Q7_2: "0", Q7_3: "1" },
  { Q0: "1", Q7: "2", Q7_1: "0", Q7_2: "1", Q7_3: "0" },
  { Q0: "1", Q7: "2", Q7_1: "0", Q7_2: "1", Q7_3: "0" },
  { Q0: "1", Q7: "3", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "1", Q7: "3", Q7_1: "0", Q7_2: "0", Q7_3: "1" },
  { Q0: "1", Q7: "3", Q7_1: "0", Q7_2: "1", Q7_3: "0" },
  { Q0: "1", Q7: "3", Q7_1: "0", Q7_2: "1", Q7_3: "0" },
  { Q0: "1", Q7: "3", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "1", Q7: "3", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "1", Q7: "3", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "1", Q7: "4", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "1", Q7: "4", Q7_1: "0", Q7_2: "1", Q7_3: "0" },
  { Q0: "1", Q7: "4", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "1", Q7: "4", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "1", Q7: "4", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "1", Q7: "4", Q7_1: "0", Q7_2: "0", Q7_3: "1" },
  { Q0: "1", Q7: "4", Q7_1: "0", Q7_2: "0", Q7_3: "1" },
  { Q0: "2", Q7: "1", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "2", Q7: "1", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "2", Q7: "1", Q7_1: "0", Q7_2: "1", Q7_3: "0" },
  { Q0: "2", Q7: "1", Q7_1: "0", Q7_2: "0", Q7_3: "1" },
  { Q0: "2", Q7: "1", Q7_1: "0", Q7_2: "0", Q7_3: "1" },
  { Q0: "2", Q7: "2", Q7_1: "0", Q7_2: "0", Q7_3: "1" },
  { Q0: "2", Q7: "2", Q7_1: "0", Q7_2: "1", Q7_3: "0" },
  { Q0: "2", Q7: "2", Q7_1: "0", Q7_2: "1", Q7_3: "0" },
  { Q0: "2", Q7: "3", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "2", Q7: "3", Q7_1: "0", Q7_2: "0", Q7_3: "1" },
  { Q0: "2", Q7: "3", Q7_1: "0", Q7_2: "1", Q7_3: "0" },
  { Q0: "2", Q7: "3", Q7_1: "0", Q7_2: "1", Q7_3: "0" },
  { Q0: "2", Q7: "3", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "2", Q7: "3", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "2", Q7: "3", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "2", Q7: "4", Q7_1: "1", Q7_2: "0", Q7_3: "0" },
  { Q0: "2", Q7: "4", Q7_1: "0", Q7_2: "1", Q7_3: "0" },
];

export const MANUFACTURER_PREFERENCE_BAR_CHART_DATA = [
  { Q0: "1", Q8: "7" },
  { Q0: "1", Q8: "7" },
  { Q0: "1", Q8: "7" },
  { Q0: "2", Q8: "7" },
  { Q0: "2", Q8: "7" },
  { Q0: "3", Q8: "7" },
  { Q0: "1", Q8: "7" },
  { Q0: "1", Q8: "7" },
  { Q0: "1", Q8: "6" },
  { Q0: "1", Q8: "6" },
  { Q0: "2", Q8: "6" },
  { Q0: "3", Q8: "6" },
  { Q0: "1", Q8: "6" },
  { Q0: "1", Q8: "5" },
  { Q0: "1", Q8: "5" },
  { Q0: "1", Q8: "4" },
  { Q0: "1", Q8: "3" },
  { Q0: "1", Q8: "2" },
  { Q0: "1", Q8: "4" },
  { Q0: "1", Q8: "1" },
  { Q0: "1", Q8: "1" },
  { Q0: "1", Q8: "1" },
];

export const SWITCHING_MANUFACTURER_BAR_CHART_DATA = [
  { Q0: "1", Q9: "7" },
  { Q0: "1", Q9: "7" },
  { Q0: "1", Q9: "6" },
  { Q0: "2", Q9: "7" },
  { Q0: "2", Q9: "7" },
  { Q0: "3", Q9: "4" },
  { Q0: "1", Q9: "6" },
  { Q0: "1", Q9: "6" },
  { Q0: "1", Q9: "5" },
  { Q0: "1", Q9: "6" },
  { Q0: "2", Q9: "5" },
  { Q0: "3", Q9: "6" },
  { Q0: "3", Q9: "6" },
  { Q0: "1", Q9: "6" },
  { Q0: "1", Q9: "4" },
  { Q0: "1", Q9: "5" },
  { Q0: "1", Q9: "6" },
  { Q0: "1", Q9: "3" },
  { Q0: "1", Q9: "3" },
  { Q0: "1", Q9: "2" },
  { Q0: "1", Q9: "1" },
  { Q0: "1", Q9: "1" },
];

export const LINE_CHART_BAR_DATA = [
  {
    Q0: "1",
    Q3: "1",
    Q3_1: "10",
    Q3_2: "30",
    Q3_3: "58",
    Q3_4: "87",
    Q3_5: "108",
  },
  {
    Q0: "2",
    Q3: "1",
    Q3_1: "12",
    Q3_2: "32",
    Q3_3: "60",
    Q3_4: "89",
    Q3_5: "99",
  },
  {
    Q0: "1",
    Q3: "2",
    Q3_1: "20",
    Q3_2: "38",
    Q3_3: "43",
    Q3_4: "46",
    Q3_5: "61",
  },
  {
    Q0: "2",
    Q3: "2",
    Q3_1: "22",
    Q3_2: "40",
    Q3_3: "45",
    Q3_4: "48",
    Q3_5: "110",
  },
  {
    Q0: "1",
    Q3: "3",
    Q3_1: "8",
    Q3_2: "15",
    Q3_3: "26",
    Q3_4: "55",
    Q3_5: "59",
  },
  {
    Q0: "2",
    Q3: "3",
    Q3_1: "10",
    Q3_2: "17",
    Q3_3: "28",
    Q3_4: "57",
    Q3_5: "65",
  },
];
