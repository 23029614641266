import React, { useContext } from "react";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";

import { Col, Container, Row } from "react-bootstrap";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";

import PieChartQuestion1 from "./Screener/PieChartQuestion1";
import DoughnutChartQuestion2 from "./Screener/DoughnutChartQuestion2";
import LineChartQuestion3 from "./Screener/LineChartQuestion3";
import AreaChartQuestion4 from "./Screener/AreaChartQuestion4";
import RatingProductFeatureBarChartQuestion5 from "./Screener/RatingProductFeatureBarChartQuestion5";
import PerceivedBenefitsBarChartQuestion6 from "./OpsOpti/PerceivedBenefitsBarChartQuestion6";
import StackedBarChartQuestion7 from "./Screener/StackedBarChartQuestion7";
import ManufacturerPreferenceBarChartQuestion8 from "./PricingPreference/ManufacturerPreferenceBarChartQuestion8";
import ManufacturerPreferenceBarChartQuestion9 from "./PricingPreference/ManufacturerPreferenceBarChartQuestion9";
import { FilterContext } from "../App";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

function Home() {
  const filterContext = useContext(FilterContext);

  return (
    <Container>
      <div className="line-text">
        <span>Screener</span>
      </div>
      <Row>
        <Col sm={12} md={6}>
          <PieChartQuestion1
            countryFilterValue={filterContext.state.countryFilterValue}
          />
        </Col>
        <Col sm={12} md={6}>
          <DoughnutChartQuestion2
            countryFilterValue={filterContext.state.countryFilterValue}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <LineChartQuestion3
            countryFilterValue={filterContext.state.countryFilterValue}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AreaChartQuestion4 />
        </Col>
      </Row>
      <Row>
        <Col>
          <RatingProductFeatureBarChartQuestion5
            countryFilterValue={filterContext.state.countryFilterValue}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <PerceivedBenefitsBarChartQuestion6 />
        </Col>
      </Row>
      <Row>
        <Col>
          <StackedBarChartQuestion7
            countryFilterValue={filterContext.state.countryFilterValue}
          />
        </Col>
      </Row>
      <div className="line-text">
        <span>Pricing Preferences</span>
      </div>
      <Row>
        <Col sm={12} md={6}>
          <ManufacturerPreferenceBarChartQuestion8
            countryFilterValue={filterContext.state.countryFilterValue}
          />
        </Col>
        <Col sm={12} md={6}>
          <ManufacturerPreferenceBarChartQuestion9
            countryFilterValue={filterContext.state.countryFilterValue}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
