import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  COLORS_RYGU,
  PERCEIVED_BENEFITS_Q6_1_LIST,
  QUESTION_6,
} from "../../constants/constants.js";
import { Card } from "react-bootstrap";

import { useContext } from "react";
import { ColorPaletteContext } from "../../App.js";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

function PerceivedBenefitsBarChartQuestion6(props) {
  //const { dashboardData } = useAPI();

  const colorPaletteContext = useContext(ColorPaletteContext);
  const { colorPaletteState, updateColorPaletteState } = colorPaletteContext;

  const labels = [
    { key: 1, value: "1-I don't anticipate this impact" },
    { key: 2, value: "2" },
    { key: 3, value: "3" },
    { key: 4, value: "4" },
    { key: 5, value: "5" },
    { key: 6, value: "6" },
    { key: 7, value: "7-I anticipate this impact" },
  ];

  function getAveragePercentageForEachOption() {
    return [
      ["7", "6", "15", "17", "31", "21", "3"],
      ["3", "14", "12", "14", "27", "22", "8"],
      ["6", "12", "11", "13", "22", "27", "9"],
      ["6", "9", "11", "15", "24", "26", "9"],
      ["4", "10", "11", "16", "24", "25", "11"],
      ["5", "10", "10", "16", "26", "24", "10"],
      ["4", "10", "17", "17", "18", "24", "10"],
      ["4", "14", "10", "11", "25", "29", "7"],
      ["5", "9", "12", "13", "30", "22", "10"],
      ["4", "12", "10", "17", "22", "24", "11"],
      ["3", "10", "14", "13", "21", "29", "10"],
      ["50", "17", "0", "0", "17", "0", "17"],
    ];
  }

  function getOptions() {
    return {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 0, // Add left padding
          right: 0, // Add right padding
          top: 50, // Add top padding
          bottom: 5, // Add bottom padding
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            title: (context) => {
              return context[0].label.replaceAll(",", " ");
            },
            label: (tooltipItem) => {
              return tooltipItem.formattedValue + "%";
            },
          },
        },
        legend: {
          display: true,
          position: "bottom",
          onClick: null, // Disable legend item click event
        },
        datalabels: {
          rotation: -90,
          formatter: (value) => {
            if (value) {
              return value + " %";
            }
          },
          anchor: "end",
          align: "top",
          //offset: "0",
          color: "grey",
          font: {
            weight: "bold",
            size: 13,
          },
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 8,
            },
          },
        },
        y: {
          grid: {
            display: false,
          },
          display: false,
        },
      },
    };
  }

  function getChartData() {
    return {
      labels: PERCEIVED_BENEFITS_Q6_1_LIST.map((item, index) => item.value),
      datasets: labels.map((label, index) => ({
        label: label.value,
        data: getAveragePercentageForEachOption().map(
          (questionResponses) => questionResponses[index]
        ),
        backgroundColor: colorPaletteState[index],
      })),
    };
  }

  return (
    <Card>
      <Card.Header>{QUESTION_6}</Card.Header>
      <Card.Body>
        <div style={{ width: "100%", height: "60vh" }}>
          <Bar options={getOptions()} data={getChartData()} />
        </div>
      </Card.Body>
    </Card>
  );
}
export default PerceivedBenefitsBarChartQuestion6;
