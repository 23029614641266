import React from "react";
import styled from "styled-components";
import Navigation from "./Nav";

const Header = () => {
  return (
    <div>
      {/* <NavLink to="https://www.tehrihills.com/">
        <img
          src="./tehri-hills.png"
          alt="my logo img"
          style={{ height: "75px" }}
        />
      </NavLink> */}
      <Navigation />
    </div>
  );
};

const MainHeader = styled.header`
  padding: 0 2rem;
  height: 4rem;
  background-color: rgb(230, 229, 229) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 10px;

  .logo {
    height: 5rem;
  }
`;
export default Header;
