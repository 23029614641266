import React, { useContext } from "react";
import { Line } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
} from "chart.js";

import { AREA_CHART_LABELS, QUESTION_4 } from "../../constants/constants";
import { ColorPaletteContext } from "../../App";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels,
  Filler
);

const AreaChartQuestion4 = () => {
  const colorPaletteContext = useContext(ColorPaletteContext);
  const { colorPaletteState } = colorPaletteContext;

  const data = {
    labels: AREA_CHART_LABELS.map((item) => item.value),
    datasets: [
      {
        label: "Bank Transfer",
        data: [65, 50, 52, 53, 48, 55, 43, 41, 58, 60, 61, 65],
        borderColor: colorPaletteState[0],
        backgroundColor: colorPaletteState[0],
        fill: true,
      },
      {
        label: "Credit Card",
        data: [75, 65, 62, 63, 58, 61, 53, 51, 68, 70, 71, 71],
        borderColor: colorPaletteState[1],
        backgroundColor: colorPaletteState[1],
        fill: true,
      },
      {
        label: "Debit Card",
        data: [85, 70, 72, 73, 68, 71, 63, 61, 78, 80, 81, 87],
        borderColor: colorPaletteState[2],
        backgroundColor: colorPaletteState[2],
        fill: true,
      },
      {
        label: "Paypal",
        data: [95, 80, 82, 83, 78, 86, 73, 71, 88, 90, 91, 92],
        borderColor: colorPaletteState[3],
        backgroundColor: colorPaletteState[3],
        fill: true,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    stacked: false,
    scales: {
      y: {
        title: {
          display: true,
          text: "(Number of Sales)",
          font: {
            size: 16,
          },
        },
        beginAtZero: true,
        grid: {
          //display: false,
        },
      },
      x: {
        title: {
          display: false,
          text: "(Month)",
          font: {
            size: 16,
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        title: { display: true, padding: 10 },
        onClick: null, // Disable legend item click event
      },
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <Card>
      <Card.Header>{QUESTION_4}</Card.Header>
      <Card.Body>
        <div style={{ width: "100%", height: "60vh" }}>
          <Line data={data} options={options} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default AreaChartQuestion4;
