// replace the user pool region, id, and app client id details
// export default {
//   REGION: "ap-southeast-2",
//   USER_POOL_ID: "ap-southeast-2_Kz1MgYR6h",
//   USER_POOL_APP_CLIENT_ID: "h4p3m5a4ca9lk9f8ddvpecf7v",
// };

export default {
  REGION: "ap-southeast-2",
  USER_POOL_ID: "ap-southeast-2_SN1ODxNfo",
  USER_POOL_APP_CLIENT_ID: "6dd4461jm81ie4d0uduqaf7u0u",
};
