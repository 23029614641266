import React, { useContext } from "react";
import { Line } from "react-chartjs-2";

import { Card } from "react-bootstrap";
import annotationPlugin from "chartjs-plugin-annotation";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJs,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import {
  LINE_CHART_BAR_DATA,
  LINE_CHART_LABELS,
  LINE_CHART_LIST,
  QUESTION_3,
} from "../../constants/constants";
import { ColorPaletteContext } from "../../App";

ChartJs.register(
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  annotationPlugin,
  ChartDataLabels
);

const LineChartQuestion3 = (props) => {
  const colorPaletteContext = useContext(ColorPaletteContext);
  const { colorPaletteState } = colorPaletteContext;

  function getAveragePercentageForEachOption(index, resultArray = []) {
    if (props.countryFilterValue) {
      resultArray = resultArray.filter(
        (item) => item.Q0 === props.countryFilterValue
      );
    }

    var temp = resultArray.filter((item) => item.Q3 === index);
    if (!temp.length > 0) {
      return [];
    }

    var subset = {
      Q3_1: temp[0].Q3_1,
      Q3_2: temp[0].Q3_2,
      Q3_3: temp[0].Q3_3,
      Q3_4: temp[0].Q3_4,
      Q3_5: temp[0].Q3_5,
    };

    return Object.values(subset).map((item) => Number(item));
  }

  // Data for the line chart
  const data = {
    labels: LINE_CHART_LABELS.map((item) => item.value),
    // datasets: [
    //   {
    //     label: "Fast food Meals",
    //     data: [10, 30, 58, 87, 108],
    //     borderColor: colorPaletteState[0],
    //     backgroundColor: colorPaletteState[0],
    //   },
    //   {
    //     label: "Sit-down Restaurant Meals",
    //     data: [20, 38, 43, 46, 61],
    //     borderColor: colorPaletteState[1],
    //     backgroundColor: colorPaletteState[1],
    //   },
    //   {
    //     label: "Drive-thru Meals",
    //     data: [8, 15, 26, 55, 59],
    //     borderColor: colorPaletteState[2],
    //     backgroundColor: colorPaletteState[2],
    //   },
    // ],
    // ],
    datasets: LINE_CHART_LIST.map((item, index) => ({
      label: item.value,
      data: getAveragePercentageForEachOption(
        item.realValue,
        LINE_CHART_BAR_DATA
      ),
      borderColor: colorPaletteState[index],
      backgroundColor: colorPaletteState[index],
    })),
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    stacked: false,
    scales: {
      y: {
        title: {
          display: true,
          text: "(Number of Meals / Per Year)",
          font: {
            size: 16,
          },
        },
        beginAtZero: true,
        grid: {
          //display: false,
        },
      },
      x: {
        title: {
          display: false,
          text: "(Month)",
          font: {
            size: 16,
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        title: { display: true, padding: 10 },
        onClick: null, // Disable legend item click event
      },
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <Card>
      <Card.Header>{QUESTION_3}</Card.Header>
      <Card.Body>
        <div style={{ width: "100%", height: "60vh" }}>
          <Line data={data} options={options} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default LineChartQuestion3;
